<template>
  <div>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="8">
        <v-file-input
          :disabled="disabledEdit()"
          show-size
          label="Seleccionar Archivo"
          @change="selectFile"
        ></v-file-input>
      </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn
          :disabled="disabledEdit()"
          color="success"
          dark
          small
          @click="upload"
        >
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="message" border="left" color="blue-grey" dark>
      {{ message }}
    </v-alert>

    <!-- <div v-if="currentFile">
      <div>
        <v-progress-linear
          v-model="progress"
          color="light-blue"
          height="25"
          reactive
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </div>
    </div> -->

    <v-card
      v-if="fileProperties.length > 0 || fileAmenities.length > 0"
      class="mx-auto"
    >
      <v-tabs>
        <v-tab>
          Lista de propiedades
        </v-tab>
        <v-tab>
          Lista de Amenidades
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              :headers="headersProperties"
              :items="propertyList"
              sort-by="code"
              class="elevation-1"
              :item-class="propertyItemRowBackground"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <!--     background-color: #ffd7d7; -->
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Nueva propiedad
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty.code"
                                label="Code"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty.contract_status"
                                label="contract_status"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['contact/web']"
                                label="contact/web"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['building/type']"
                                label="building/type"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['building/construction']"
                                label="building/construction"
                                :rules="[
                                  newPropertyFormRules['building/construction']
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['building/terrace']"
                                label="building/terrace"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['building/total']"
                                label="building/total"
                                :rules="[
                                  newPropertyFormRules['building/total']
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty['construction_allowed/unit']
                                "
                                label="construction_allowed/unit"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty['construction_allowed/total']
                                "
                                label="construction_allowed/total"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['pricing/price']"
                                label="pricing/price"
                                :rules="[newPropertyFormRules['pricing/price']]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['pricing/currency']"
                                label="pricing/currency"
                                :rules="[
                                  newPropertyFormRules['pricing/currency']
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['floor/code']"
                                label="floor/code"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['floor/name']"
                                label="floor/name"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['features/rooms']"
                                label="features/rooms"
                                :rules="[
                                  newPropertyFormRules['features/rooms']
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['features/bathrooms']"
                                label="features/bathrooms"
                                :rules="[
                                  newPropertyFormRules['features/bathrooms']
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty['media/featured_image/name']
                                "
                                label="media/featured_image/name"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty[
                                    'media/featured_image/cloudinary_id'
                                  ]
                                "
                                label="media/featured_image/cloudinary_id"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty['media/featured_image/src']
                                "
                                label="media/featured_image/src"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['media/plane_image/name']"
                                label="media/plane_image/name"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty[
                                    'media/plane_image/cloudinary_id'
                                  ]
                                "
                                label="media/plane_image/cloudinary_id"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['media/plane_image/src']"
                                label="media/plane_image/src"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="
                                  editProperty['miscellaneous/mapping_coords']
                                "
                                label="miscellaneous/mapping_coords"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['type']"
                                label="type"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['amenities']"
                                label="amenities"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editProperty['status']"
                                label="status"
                                :rules="[newPropertyFormRules['status']]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline">
                        ¿Estás seguro que quieres eliminar la propiedad?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:item[`actions`]="{ item }">
                <v-icon small class="mr-2" @click="editPropertyId(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deletePropertyId(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              :headers="headersAmenities"
              :items="amenitiesList"
              sort-by="code"
              class="elevation-1"
              :item-class="amenitiesItemRowBackground"
            >
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import XLSX from "xlsx";

export default {
  name: "ImportPropertiesExcel",
  props: {
    developmentsCode: {
      type: String,
      default: "Seleccionar broker"
    },
    developmentsName: {
      type: String,
      default: ""
    },
    developmentsId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      currentFile: undefined,
      progress: 0,
      message: "",
      fileProperties: [],
      fileAmenities: [],
      headersProperties: [],
      headersAmenities: [],
      propertyList: [],
      amenitiesList: [],
      developmentUpdate: {
        realEstateDevelopmentId: this.developmentsId,
        media_structure_src: "",
        development_structure_type: "building",
        development_structure_floors: [],
        development_structure_towers: [],
        miscellaneous_mapping_coords_structure: []
      },
      editProperty: {},
      propertyIdx: -1,
      realEstateDevelomeptAssigne: {},
      validateParamsExcel: [
        "code",
        "type",
        "pricing/price",
        "pricing/currency",
        "contract_status",
        "floor/code",
        "floor/name",
        "tower/code",
        "tower/name",
        "contact/web",
        "building/type",
        "building/construction",
        "building/contruction_front",
        "building/construction_depth",
        "building/terrace",
        "building/total",
        "construction_allowed/unit",
        "construction_allowed/total",
        "features/rooms",
        "features/bathrooms",
        "media/featured_image/name",
        "media/featured_image/title",
        "media/featured_image/src",
        "media/featured_image/cloudinary_id",
        "media/plane_image/name",
        "media/plane_image/title",
        "media/plane_image/src",
        "media/plane_image/cloudinary_id",
        "miscellaneous/mapping_coords",
        "miscellaneous/mapping_coords_building",
        "amenities",
        "status",
        "building/construction_depth_right",
        "building/construction_depth_left",
        "building/construction_back"
      ],
      role: localStorage.getItem("user_role"),
      errorsInPropertiesTab: false,
      errorsInAmenitiesTab: false,
      newPropertyFormErrors: {},
      errorMessagesProperties: "",
      errorMessagesAmenities: "",
      newPropertyFormRules: {
        // code: value => {
        //   this.newPropertyFormErrors["code_valid"] = !isNaN(value);
        //   return !isNaN(value) || "code invalido";
        // },
        "building/construction": value => {
          this.newPropertyFormErrors["building/construction_valid"] = !isNaN(
            value
          );
          return !isNaN(value) || "building/construction invalido";
        },
        "building/total": value => {
          this.newPropertyFormErrors["building/total_valid"] = !isNaN(value);
          return !isNaN(value) || "building/total invalido";
        },
        "pricing/price": value => {
          this.newPropertyFormErrors["pricing/price_valid"] = !isNaN(value);
          return !isNaN(value) || "pricing/price invalido";
        },
        "pricing/currency": value => {
          this.newPropertyFormErrors["pricing/currency_valid"] = [
            "MXN",
            "USD"
          ].includes(value);
          return ["MXN", "USD"].includes(value) || "pricing/currency invalido";
        },
        "features/rooms": value => {
          this.newPropertyFormErrors["features/rooms_valid"] = !isNaN(value);
          return !isNaN(value) || "features/rooms invalido";
        },
        "features/bathrooms": value => {
          this.newPropertyFormErrors["features/bathrooms_valid"] = !isNaN(
            value
          );
          return !isNaN(value) || "features/bathrooms invalido";
        },
        status: value => {
          this.newPropertyFormErrors["status_valid"] = !isNaN(value);
          return !isNaN(value) || "status invalido";
        }
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva propiedad" : "Editar propiedad";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    ...mapActions({
      fetchCreateAllProperty: "properties/fetchCreateAllProperty",
      fetchCreateAllAmenities: "amenities/fetchCreateAllAmenities",
      fetchUpdateRealEstateDevelopmentExcel:
        "realEstateDevelopments/fetchUpdateRealEstateDevelopmentExcel"
    }),

    disabledEdit() {
      if (this.role === null) return true;
      if (this.role === "coordinator") return true;
      if (this.role === "admin") return false;
      return true;
    },

    selectFile(file) {
      if (file) {
        this.message = "";
        this.errorMessagesAmenities = "";
        this.errorMessagesProperties = "";
        this.errorsInPropertiesTab = false;
        this.errorsInAmenitiesTab = false;
        this.progress = 0;
        this.currentFile = file;
        this.readPropertiesExcel(file);
      } else {
        this.cleanDataTableProperty();
      }
    },

    upload() {
      if (!this.currentFile) {
        this.message = "Seleccione un archivo";
        return;
      }
      // validacion que no te permita subir inventario
      if (this.errorsInPropertiesTab || this.errorsInAmenitiesTab) {
        this.message =
          "Datos invalidos dentro del excel, favor de revisar las filas de color rojo y volver a subir el excel corregido. " +
          `Errores en pestaña de desarrollos: ${this.errorMessagesProperties} | Errores en pestaña de amenidades: ${this.errorMessagesAmenities}`;
        return;
      }
      if (this.amenitiesList.length === 0) {
        this.message = "No hay amenidades para agregar";
      } else {
        this.registerAmenities();
      }
      if (this.propertyList.length === 0) {
        this.message = "No hay propiedades para agregar";
        return;
      }
      this.message = "";
      this.registerProperties();
      this.updateRealEstateDevelopment();
    },

    registerProperties() {
      this.$snotify.async(
        "Agregando Propiedades",
        "Agregando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.fetchCreateAllProperty(this.propertyList)
                .then(() => {
                  resolve({
                    title: "Propiedades Agregadas",
                    body: "Lista de propiedades agregadas correctamente",
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema al agregar las propiedades. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  setTimeout(() => {
                    //momentaneo
                    this.propertyList = [];
                    this.headersProperties = [];
                    this.fileProperties = [];
                  }, 1000);
                });
            }, 500);
          })
      );
    },
    registerAmenities() {
      this.$snotify.async(
        "Agregando Amenidades",
        "Agregando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.fetchCreateAllAmenities(this.amenitiesList)
                .then(() => {
                  resolve({
                    title: "Agregando amenidades",
                    body: "Lista de amenidades agregadas correctamente.",
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema al agregar una amenidad. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  setTimeout(() => {
                    this.amenitiesList = [];
                    this.headersAmenities = [];
                    this.fileAmenities = [];
                    // self.setLoading(false);
                  }, 1000);
                });
            }, 1000);
          })
      );
    },
    updateRealEstateDevelopment() {
      this.$snotify.async(
        "Actualizando Desarrollo",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.fetchUpdateRealEstateDevelopmentExcel(this.developmentUpdate)
                .then(() => {
                  resolve({
                    title: "Actualizando Desarrollo",
                    body: "Actualizando ...",
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema al actualizar desarrollo. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally(() => {
                  setTimeout(() => {
                    this.developmentUpdate = {
                      realEstateDevelopmentId: this.developmentsId,
                      media_structure_src: "",
                      development_structure_type: "building",
                      development_structure_floors: [],
                      miscellaneous_mapping_coords_structure: []
                    };
                    // self.setLoading(false);
                  }, 1000);
                });
            }, 1000);
          })
      );
    },

    readPropertiesExcel(file) {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        if (wb.SheetNames.includes("Desarrollo")) {
          const wsname_property = wb.SheetNames[0];
          const ws_property = wb.Sheets[wsname_property];
          /* Convert array of arrays */
          const data_properties = XLSX.utils.sheet_to_json(ws_property, {
            header: 1
          });
          const XL_row_object_properties = XLSX.utils.sheet_to_row_object_array(
            ws_property
          );
          this.errorsInPropertiesTab = XL_row_object_properties.some(
            element => {
              return this.validateExcelDataProperties(element);
            }
          );

          // mensajes de error en propiedades
          XL_row_object_properties.forEach((item, index) => {
            const messageHeader = `- Fila en excel: ${index + 2}`;
            // if ("code" in item && isNaN(item.code)) {
            //   this.errorMessagesProperties += `${messageHeader} campo: code `;
            // }
            if (
              "building/construction" in item &&
              isNaN(item["building/construction"])
            ) {
              this.errorMessagesProperties += `${messageHeader} campo: building/construction `;
            }
            if ("building/total" in item && isNaN(item["building/total"])) {
              this.errorMessagesProperties += `${messageHeader} campo: building/total `;
            }
            if ("pricing/price" in item && isNaN(item["pricing/price"])) {
              this.errorMessagesProperties += `${messageHeader} campo: pricing/price `;
            }
            if (
              "pricing/currency" in item &&
              !["MXN", "USD"].includes(item["pricing/currency"])
            ) {
              this.errorMessagesProperties += `${messageHeader} campo: pricing/currency `;
            }
            if ("features/rooms" in item && isNaN(item["features/rooms"])) {
              this.errorMessagesProperties += `${messageHeader} campo: features/rooms `;
            }
            if (
              "features/bathrooms" in item &&
              isNaN(item["features/bathrooms"])
            ) {
              this.errorMessagesProperties += `${messageHeader} campo: features/bathrooms `;
            }
            if (
              "miscellaneous/mapping_coords_building" in item &&
              new RegExp(/[^\d,.]/).test(
                item["miscellaneous/mapping_coords_building"]
              )
            ) {
              this.errorMessagesProperties += `${messageHeader} campo: miscellaneous/mapping_coords_building `;
            }
            if ("status" in item && isNaN(item["status"])) {
              this.errorMessagesProperties += `${messageHeader} campo: status `;
            }
          });
          /* Update state */
          this.fileProperties = this.filterPropertiesExcel(data_properties);
          this.getListProperty(XL_row_object_properties);
        }
        if (wb.SheetNames.includes("Amenidades")) {
          const wsname_amenities = wb.SheetNames[3];
          const ws_amenities = wb.Sheets[wsname_amenities];
          /* Convert array of arrays */
          const data_amenities = XLSX.utils.sheet_to_json(ws_amenities, {
            header: 1
          });
          const XL_row_object_amenities = XLSX.utils.sheet_to_row_object_array(
            ws_amenities
          );
          this.errorsInAmenitiesTab = XL_row_object_amenities.some(element => {
            return this.validateExcelDataAmenities(element);
          });
          XL_row_object_amenities.forEach((item, index) => {
            const messageHeader = `- Fila en excel: ${index + 2}`;
            // if ("code" in item && isNaN(item.code)) {
            //   this.errorMessagesAmenities += `${messageHeader} campo: code `;
            // }
            if (
              "construction/total" in item &&
              isNaN(item["construction/total"])
            ) {
              this.errorMessagesAmenities += `${messageHeader} campo: construction/total `;
            }
            if (
              "construction/unit" in item &&
              !["m2", "%", ""].includes(item["construction/unit"])
            ) {
              this.errorMessagesAmenities += `${messageHeader} campo: construction/unit `;
            }
          });
          /* Update state */
          this.fileAmenities = this.filterAmenitiesExcel(data_amenities);
          this.getListAmenities(XL_row_object_amenities);
        }
        if (wb.SheetNames.includes("Helper")) {
          const wsname_helpersDevelopment = wb.SheetNames[2];
          const ws_developmentHelper = wb.Sheets[wsname_helpersDevelopment];
          const XL_row_object_development = XLSX.utils.sheet_to_row_object_array(
            ws_developmentHelper
          );
          XL_row_object_development.forEach((developHelper, idx) => {
            this.developmentUpdate.development_structure_floors.push({
              name: developHelper["NOMBRE NIVEL"],
              code: developHelper["NIVELES"],
              media: {
                especifications: {
                  src: developHelper["FLOORS/MEDIA_STRUCTURE"]
                }
              }
            });
            this.developmentUpdate.development_structure_towers.push({
              name: developHelper["NOMBRES DE TORRES"],
              code: developHelper["TORRES"]
            });
            this.developmentUpdate.miscellaneous_mapping_coords_structure.push({
              name: developHelper["NOMBRE NIVEL"],
              index: idx,
              mapping_coords: this.getMappingCoordsStructure(developHelper)
            });
            if (developHelper["MEDIA_STRUCTURE"]) {
              this.developmentUpdate.media_structure_src =
                developHelper["MEDIA_STRUCTURE"];
            }
          });
        }
      };
      reader.readAsBinaryString(file);
    },

    getMappingCoordsStructure(developHelper) {
      if (developHelper["MISCELLANEOUS/MAPPING_COORDS_STRUCTURE"]) {
        return developHelper["MISCELLANEOUS/MAPPING_COORDS_STRUCTURE"]
          .split(",")
          .map(i => Number(i, 10));
      }
      return [];
    },

    filterPropertiesExcel(rows) {
      if (rows.length === 0) return [];
      // quitar filas vacias
      rows = rows.filter(r => r[0] && r[0].length > 0);
      // quitar encabezados
      rows[0].forEach(params => {
        if (this.validateParamsExcel.indexOf(params.toString()) === -1) {
          this.errorMessageValidateExcel(params);
        }
      });
      this.getHeaderforTable(rows[0], this.headersProperties);
      // rows.shift();
      return rows;
    },

    filterAmenitiesExcel(rows) {
      if (rows.length === 0) return [];
      // quitar filas vacias
      rows = rows.filter(
        r => r[0] && r[0].length > 0 && r[0].abbreviation !== "-"
      );
      this.getHeaderforTable(rows[0], this.headersAmenities);
      return rows;
    },

    getHeaderforTable(headersExcel, array) {
      headersExcel.forEach(element => {
        const objHeader = {
          text: element,
          value: element
        };
        array.push(objHeader);
      });
      array.push({
        text: "Actions",
        value: "actions"
      });
    },

    getListProperty(propertys) {
      propertys.forEach(property => {
        if (property.code) {
          property.real_estate_development = {
            _id: this.developmentsId,
            code: this.developmentsCode,
            name: this.developmentsName
          };
          this.propertyList.push(property);
        }
      });
    },

    getListAmenities(amenities) {
      amenities.forEach(amenitie => {
        if (amenitie.code) {
          amenitie.real_estate_development = {
            _id: this.developmentsId,
            code: this.developmentsCode,
            name: this.developmentsName
          };
          this.amenitiesList.push(amenitie);
        }
      });
    },

    editPropertyId(item) {
      this.propertyIdx = this.propertyList.indexOf(item);
      this.editProperty = Object.assign({}, item);
      this.dialog = true;
    },

    deletePropertyId(item) {
      this.propertyIdx = this.propertyList.indexOf(item);
      this.editProperty = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.propertyList.splice(this.propertyIdx, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editProperty = {};
        this.propertyIdx = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editProperty = {};
        this.propertyIdx = -1;
      });
    },

    save() {
      let errorsInNewPropertyForm =
        //this.newPropertyFormErrors["code_valid"] === false ||
        this.newPropertyFormErrors["status_valid"] === false ||
        this.newPropertyFormErrors["building/construction_valid"] === false ||
        this.newPropertyFormErrors["building/total_valid"] === false ||
        this.newPropertyFormErrors["pricing/price_valid"] === false ||
        this.newPropertyFormErrors["pricing/currency_valid"] === false ||
        this.newPropertyFormErrors["features/rooms_valid"] === false ||
        this.newPropertyFormErrors["features/bathrooms_valid"] === false;
      if (errorsInNewPropertyForm) {
        return;
      }
      if (this.propertyIdx > -1) {
        Object.assign(this.propertyList[this.propertyIdx], this.editProperty);
      } else {
        this.editProperty.real_estate_development = {
          _id: this.developmentsId,
          code: this.developmentsCode,
          name: this.developmentsName
        };
        this.propertyList.push(this.editProperty);
      }
      this.close();
    },

    cleanDataTableProperty() {
      this.propertyList = [];
      this.amenitiesList = [];
      this.headersProperties = [];
      this.headersAmenities = [];
      this.fileProperties = [];
      this.fileAmenities = [];
    },

    errorMessageValidateExcel(notParam) {
      this.$snotify.error(
        "Error en el documento revisar la siguiente columna",
        `${notParam}`,
        {
          closeOnClick: true,
          maxOnScreen: 1
        }
      );
    },
    propertyItemRowBackground(item) {
      return this.itemRowBackground(item, "properties");
    },
    amenitiesItemRowBackground(item) {
      return this.itemRowBackground(item, "amenities");
    },
    itemRowBackground: function(item, type) {
      let rowClassName = "";
      let invalidData = false;
      if (type === "properties") {
        invalidData = this.validateExcelDataProperties(item);
      } else {
        invalidData = this.validateExcelDataAmenities(item);
      }
      if (invalidData) {
        // si es true se pinta de rojo
        rowClassName = "bad-input-properties-table";
      }
      return rowClassName;
    },
    validateExcelDataProperties(item) {
      let mappingCoordsRegex = new RegExp(/[^\d,.]/);
      //return isNaN(item.code);
      let isInvalid =
        // revisar que code es numerico
        //("code" in item && isNaN(item.code)) ||
        // revisar que building/construction sea numerico
        ("building/construction" in item &&
          isNaN(item["building/construction"])) ||
        // revisar que building/total sea numerico
        ("building/total" in item && isNaN(item["building/total"])) ||
        // revisar que pricing/price sea numerico
        ("pricing/price" in item && isNaN(item["pricing/price"])) ||
        // revisar que pricing/currency solo sea MXN o USD
        ("pricing/currency" in item &&
          !["MXN", "USD"].includes(item["pricing/currency"])) ||
        // que features/rooms sea numerico
        ("features/rooms" in item && isNaN(item["features/rooms"])) ||
        // que features/bathrooms sea numerico
        ("features/bathrooms" in item && isNaN(item["features/bathrooms"])) ||
        // que miscellaneous/mapping_coords_building solo permita numeros decimales y enteros separados por comas
        ("miscellaneous/mapping_coords_building" in item &&
          mappingCoordsRegex.test(
            item["miscellaneous/mapping_coords_building"]
          )) ||
        // que status sea numerico
        ("status" in item && isNaN(item["status"]));
      return isInvalid;
    },
    validateExcelDataAmenities(item) {
      //return isNaN(item.code);
      let isInvalid =
        // revisar que code es numerico
        //("code" in item && isNaN(item.code)) ||
        // revisar que construction/total sea numerico
        ("construction/total" in item && isNaN(item["construction/total"])) ||
        // revisar que construction/unit solo sea m2,% o ''
        ("construction/unit" in item &&
          !["m2", "%", ""].includes(item["construction/unit"]));

      return isInvalid;
    }
  },
  mounted() {}
};
</script>

<style>
.bad-input-properties-table {
  background-color: #ffd7d7 !important;
}
</style>
